.data-table {
  width: 100%;
  overflow: auto;
  padding-bottom: 15px;
  background-color: #fff;
}

.scrollable-data-table {
  max-height: 500px;
  overflow: auto;
}

.scrollable-data-table-with-footer {
  max-height: 550px;
  overflow: auto;
  padding-bottom: 0px;
}

.mat-column-star {
  width: 40px;
  padding-left: 15px;
}

.data-table > table {
  width: 100%;
  font-size: 10px;
}

.data-table th {
  font-family: kalpurush, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-size: 14px;
  color: #333;
  /* font-weight: 600; */
  clear: both;
}

.mat-paginator {
  font-family: kalpurush, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

tr:hover {
  background: #fff !important;
}

th:hover {
  background: #fff !important;
}

tr.mat-footer-row,
tr.mat-row,
tr.mat-header-row {
  height: 32px !important;
}

.mat-table {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
    "kalpurush", sans-serif !important;
  font-weight: 400 !important;
  table-layout: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 1.42857143;
  color: #333;
}

.mat-footer-row,
.mat-header-row,
.mat-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.06);
}

.mat-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-cell {
  padding-left: 10px !important;
}

.mat-header-cell {
  padding-left: 10px !important;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding-left: 10px !important;
}

.space-10 {
  margin-right: 10px;
}

.space-15 {
  margin-right: 15px;
}

.mat-checkbox-checkmark {
  top: -11px !important;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.mat-cell-action {
  padding-right: 20px !important;
}

.column-number {
  text-align: right;
  padding-right: 20px !important;
}

.mat-column-noOfRecords .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-noOfSMSSentRecords .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.mat-column-star {
  width: 40px;
  padding-left: 15px;
}

.mat-column-select {
  overflow: initial;
  padding-left: 15px !important;
}

.mat-column-index {
  width: 30px;
}

.mat-column-name {
  width: 100px;
}

.mat-column-description {
  width: 100px;
}

.mat-column-companyName {
  width: 120px;
}

.mat-column-processorName {
  width: 120px;
}

.mat-column-creationDate {
  width: 80px;
}

.mat-column-submitionDate {
  width: 80px;
}

.mat-column-lastEdited {
  width: 90px;
}

.mat-column-createdBy {
  width: 80px;
}

.mat-column-lineNoInFile {
  width: 90px;
}

.mat-column-value {
  width: 400px;
}

.mat-column-reason {
  width: 100px;
}

.mat-column-noOfRecords {
  width: 90px;
}

.mat-column-noOfSMSSentRecords {
  width: 90px;
}

.mat-column-preloadedBy {
  width: 80px;
}

.mat-column-preloadedOn {
  width: 80px;
}

.mat-column-transferredBy {
  width: 80px;
}

.mat-column-transferredOn {
  width: 80px;
}

.mat-column-size {
  width: 80px;
}

.mat-column-status {
  width: 80px;
}

.mat-column-sessionStatus {
  width: 50px;
}

.mat-column-userStatus {
  width: 50px;
}

.mat-column-currentStatus {
  width: 100px;
}

.mat-column-smsStatus {
  width: 80px;
}

.mat-column-action {
  width: 65px;
}

.mat-column-actionDate {
  width: 80px;
}

.mat-column-policyHolderId {
  width: 60px;
}

.mat-column-mobileNo {
  width: 105px;
}

.mat-column-validityPeriod {
  width: 100px;
}

.mat-column-applicationType {
  width: 120px;
}

.mat-column-identifier {
  width: 100px;
}

.mat-column-startTime {
  width: 120px;
}

.mat-column-endTime {
  width: 120px;
}

.mat-column-application {
  width: 100px;
}

.mat-column-departmentName {
  width: 70px;
}

.mat-column-designation {
  width: 70px;
}

.mat-column-type {
  width: 80px;
}

.mat-column-sentBy {
  width: 80px;
}

.mat-column-sentOn {
  width: 80px;
}

.mat-column-providerName {
  width: 100px;
}

.mat-column-validatedBy {
  width: 80px;
}

.mat-column-validatedOn {
  width: 80px;
}

.mat-column-loadedBy {
  width: 80px;
}

.mat-column-loadedOn {
  width: 80px;
}

.mat-column-rejectedBy {
  width: 80px;
}

.mat-column-rejectedOn {
  width: 80px;
}

.mat-column-createdOn {
  width: 70px;
}

.mat-column-lockedBy {
  width: 70px;
}

.mat-column-lockedOn {
  width: 70px;
}

.mat-column-editedBy {
  width: 70px;
}

.mat-column-beingEditBy {
  width: 70px;
}

.mat-column-queueStarted {
  width: 80px;
}

.mat-column-smsSendEnded {
  width: 80px;
}

.mat-column-smsSendStarted {
  width: 80px;
}

.mat-column-queueEnded {
  width: 80px;
}

.mat-column-sendSMS {
  width: 50px;
}

.mat-column-sms {
  width: 200px;
}

.mat-column-endorsementNumber {
  width: 90px;
}

.mat-column-referencePolicyNumber {
  width: 60px;
}

.mat-column-addendumNumber {
  width: 90px;
}

.mat-column-referenceCoverNumber {
  width: 60px;
}

.mat-column-mrNumber {
  width: 120px;
}

.mat-column-certificateNumber {
  width: 60px;
}

.mat-column-chequeDrawnOn {
  width: 100px;
}

.mat-column-mrDate {
  width: 90px;
}

.mat-column-endorsementDate {
  width: 60px;
}

.mat-column-addendumDate {
  width: 60px;
}

.mat-column-changePremium {
  width: 60px;
}

.mat-column-changeVat {
  width: 60px;
}

.mat-column-returnVat {
  width: 60px;
}

.mat-column-changeStamp {
  width: 60px;
}

.mat-column-returnStamp {
  width: 60px;
}

.mat-column-changeOthers {
  width: 60px;
}

.mat-column-returnOthers {
  width: 60px;
}

.mat-column-changeTotalPremium {
  width: 60px;
}

.mat-column-returnTotalPremium {
  width: 60px;
}

.mat-column-returnPremium {
  width: 60px;
}

.mat-column-chequeDate {
  width: 90px;
}

.mat-column-productNumber {
  width: 60px;
}

.mat-column-receivedAmount {
  width: 90px;
}

.mat-column-receivedDate {
  width: 90px;
}

.mat-column-amount {
  width: 90px;
}

.mat-column-companyId {
  width: 90px;
}

.mat-column-btnAction {
  width: 140px;
}

.mat-column-loginId {
  width: 70px;
}

.mat-column-batchId {
  width: 90px;
}

.mat-column-name {
  width: 100px;
}

.mat-column-btnActions {
  width: 148px;
}

.mat-column-btnTerminate {
  width: 100px;
}

.mat-column-roleId {
  width: 100px;
}

.mat-column-personType {
  width: 70px;
}

.mat-column-transactionId {
  width: 100px;
}

.mat-column-year {
  width: 40px;
}

.mat-column-btnActionCa {
  width: 200px;
}

.mat-column-actionca {
  width: 100px;
}

.mat-column-uploadDate {
  width: 80px;
}

.mat-column-fileType {
  width: 80px;
}

.mat-column-checkbox {
  width: 30px;
}

/**Start Cover Policy Column width css**/

.mat-column-coverNoteNumber {
  width: 150px;
}

.mat-column-coverNoteIssueDate {
  width: 95px;
}

.mat-column-tntNumber {
  width: 90px;
}

.mat-column-coverageStartDate {
  width: 90px;
}

.mat-column-coverageEndDate {
  width: 90px;
}

.mat-column-insuranceType {
  width: 90px;
}

.mat-column-cancelledCover {
  width: 90px;
}

.mat-column-cancelledPolicy {
  width: 90px;
}

.mat-column-classSubType {
  width: 90px;
}

.mat-column-interestCovered {
  width: 90px;
}

.mat-column-sumInsuredAmount {
  width: 105px;
}

.mat-column-netPremium {
  width: 90px;
}

.mat-column-vat {
  width: 90px;
}

.mat-column-stamp {
  width: 90px;
}

.mat-column-others {
  width: 90px;
}

.mat-column-coverCancellationDate {
  width: 140px;
  text-align: center;
}

.mat-column-policyCancellationDate {
  width: 140px;
  text-align: center;
}

/**End Cover Policy Column width css**/

/**Start OR Table Column width css**/

.mat-column-orSerialNumber {
  width: 90px;
}

.mat-column-officeBranchName {
  width: 120px;
}

.mat-column-officeBranchCode {
  width: 90px;
}

.mat-column-totalPremiumPaidSoFar {
  width: 120px;
}

.mat-column-modeOfPayment {
  width: 80px;
}

.mat-column-orType {
  width: 70px;
}

.mat-column-dueDate {
  width: 90px;
}

.mat-column-fromInstallment {
  width: 90px;
}

.mat-column-toInstallment {
  width: 90px;
}

.mat-column-premiumUnitAmount {
  width: 110px;
}

.mat-column-totalPremiumAmount {
  width: 120px;
}

.mat-column-lateFee {
  width: 80px;
}

.mat-column-suspenseAmount {
  width: 90px;
}

.mat-column-totalPayableAmount {
  width: 120px;
}

.mat-column-orId {
  width: 90px;
}

.mat-column-prId {
  width: 90px;
}

.mat-column-prIds {
  width: 90px;
}

.mat-column-orDate {
  width: 90px;
}

.mat-column-prDate {
  width: 90px;
}

.mat-column-paymentDetail {
  width: 80px;
}

.mat-column-depositedToBank {
  width: 100px;
}

.mat-column-depositedToBranch {
  width: 100px;
}

.mat-column-depositedToAccountNumber {
  width: 110px;
}

.mat-column-mfs {
  width: 90px;
}

.mat-column-mfsAccountNumber {
  width: 90px;
}

.mat-column-depositDate {
  width: 90px;
}

/**End OR Table Column width css**/

/**Start MR Table Column width css**/

.mat-column-mrSerialNumber {
  width: 90px;
}

.mat-column-isCoInsurance {
  width: 90px;
}

.mat-column-isLeader {
  width: 90px;
}

.mat-column-financingBankName {
  width: 120px;
}

.mat-column-financingBankAddress {
  width: 120px;
}

.mat-column-financingBankEmail {
  width: 120px;
}

.mat-column-financingBankMobile {
  width: 105px;
}

.mat-column-classInsurance {
  width: 100px;
}

.mat-column-insuredAddress {
  width: 120px;
}

.mat-column-insuredEmail {
  width: 120px;
}

.mat-column-insuredMobile {
  width: 120px;
}

.mat-column-isMultiDocument {
  width: 90px;
}

.mat-column-bankGuaranteeNumber {
  width: 125px;
}

/**End MR Table Column width css**/

/**Start Policy Column width css**/

.mat-column-policyNumber {
  width: 120px;
}

.mat-column-insuredName {
  width: 120px;
}

.mat-column-projectCode {
  width: 90px;
}

.mat-column-policyHolderName {
  width: 120px;
}

.mat-column-mobileNumber {
  width: 105px;
}

.mat-column-dateOfBirth {
  width: 90px;
}

.mat-column-policyStartDate {
  width: 90px;
}

.mat-column-policyEndDate {
  width: 90px;
}

.mat-column-productName {
  width: 110px;
}

.mat-column-premiumMode {
  width: 90px;
  text-align: center;
}

.mat-column-term {
  width: 60px;
  text-align: center;
}

.mat-column-assuredSum {
  width: 110px;
}

.mat-column-externalLoad {
  width: 100px;
}

.mat-column-lifePremium {
  width: 100px;
}

.mat-column-supplyPremium {
  width: 100px;
}

.mat-column-totalPremium {
  width: 100px;
}

.mat-column-address {
  width: 120px;
}

.mat-column-district {
  width: 100px;
}

.mat-column-postalCode {
  width: 90px;
}

.mat-column-productCode {
  width: 90px;
  text-align: center;
}

.mat-column-gender {
  width: 90px;
}

.mat-column-email {
  width: 100px;
}

.mat-column-riskStartDate {
  width: 90px;
}

.mat-column-policyIssueDate {
  width: 90px;
}

.mat-column-identificationNumber {
  width: 100px;
}

.mat-column-policyType {
  width: 80px;
}

.mat-column-identificationType {
  width: 100px;
}

.mat-column-nextPremiumDueDate {
  width: 115px;
  text-align: center;
}

.mat-column-noOfPaidInstallment {
  width: 100px;
  text-align: center;
}

.mat-column-totalPaidAmount {
  width: 110px;
}

.mat-column-agentId {
  width: 90px;
}

.mat-column-agentMobileNo {
  width: 110px;
}

.mat-column-agentName {
  width: 120px;
}

/**End Policy Column width css**/

/**Start Monitor Sms status**/

.mat-column-orCount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-mrCount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-noOfSMSGeneration .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-noOfSendSMS .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-failedSMS .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}
/* .mat-column-mrCount {
    width: 75px;
} */

/* .mat-column-noOfSMSGeneration {
    width: 75px;
}

.mat-column-noOfSendSMS {
    width: 75px;
}

.mat-column-failedSMS {
    width: 75px;
} */

.mat-column-notificationType {
  width: 100px;
}

.mat-column-date {
  width: 75px;
}

.mat-column-smsCount {
  width: 50px;
}

/**End Monitor Sms status**/

.mat-column-toEmail {
  width: 130px;
}

.mat-column-message {
  width: 300px;
}

.mat-column-agentApplicationTypeOid {
  width: 180px;
}

.mat-column-numberOfApplications {
  width: 100px;
  text-align: center;
}

.mat-column-slotsRemaining {
  width: 100px;
  text-align: center;
}

.mat-column-applicationId {
  width: 90px;
}

.mat-column-totalFee {
  width: 70px;
}

.w-25 {
  width: 25px;
}
.w-30 {
  width: 30px;
}

.w-50 {
  width: 50px;
}

.w-60 {
  width: 60px;
}

.w-75 {
  width: 75px;
}

.w-85 {
  width: 85px;
}

.w-100 {
  width: 100px;
}

.w-125 {
  width: 125px;
}

.w-115 {
  width: 115px;
}

.w-150 {
  width: 150px;
}

.w-175 {
  width: 175px;
}

.w-200 {
  width: 200px;
}

.w-450 {
  width: 450px;
}

.w-500 {
  width: 500px;
}

.td-center {
  text-align: center;
}

.td-right {
  text-align: right;
}

.td-left {
  text-align: left;
}

.mat-column-projectCode .mat-sort-header-button {
  padding-right: 30px;
}

.mat-column-officeBranchCode .mat-sort-header-button {
  padding-right: 30px;
}

.mat-column-officeBranchName .mat-sort-header-button {
  padding-right: 15px;
}

.mat-column-term .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-productCode .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-premiumMode .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-agentMobileNo .mat-sort-header-button {
  padding-right: 15px;
}

.mat-column-lifePremium .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-supplyPremium .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-supplyPremium .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-supplyPremium .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-externalLoad .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-externalLoad .mat-sort-header-button {
  padding-left: 20px;
}

.mat-column-assuredSum .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-totalPremium .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-totalPaidAmount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-totalPaidAmount .mat-sort-header-button {
  padding-left: 25px;
}

.mat-column-others .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-stamp .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-vat .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-netPremium .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-sumInsuredAmount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-sumInsuredAmount .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-premiumUnitAmount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}
.mat-column-premiumUnitAmount .mat-sort-header-button {
  padding-left: 10px;
}

.mat-column-totalPremiumAmount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-lateFee .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}
.mat-column-suspenseAmount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-suspenseAmount .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-totalPayableAmount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-totalPayableAmount .mat-sort-header-button {
  padding-left: 15px;
}

.mat-column-totalPremiumPaidSoFar .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-totalPremiumPaidSoFar .mat-sort-header-button {
  padding-left: 5px;
}

.mat-column-modeOfPayment .mat-sort-header-button {
  padding-right: 18px;
}

.mat-column-noOfSms .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-telcoSmsCount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-noOfPolicy .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

.mat-column-smsCount .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

/* .mat-sort-header-button {
    text-align: left !important;
} */

.w-p-30 {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 30% !important;
  width: 30% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.w-p-20 {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 20% !important;
  width: 20% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.w-p-15 {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 15% !important;
  width: 15% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.w-p-10 {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 10% !important;
  width: 10% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.action-column{
  text-align: right; 
  padding-right: 10px; 
  overflow: visible !important; 
  text-overflow: unset !important;
}