/* You can add global styles to this file, and also import other style files */
/* @import "./assets/content/css/web.css"; */
/* @import './assets/content/css/open-sans.css'; */
@import "./assets/content/css/source-sans-pro.css";
@import "./assets/content/fonts/kalpurush/kalpurush.css";
@import "./assets/content/css/app.css";
@import "./assets/content/css/bootstrap.css";
@import "./assets/content/css/font-awesome.css";
@import "./assets/content/css/select2.css";
@import "./assets/content/css/datatable.css";

html,
body {
  margin: 0 !important;
  background-color: #f9fafc;
  padding-right: 0px !important;
}

.appContent {
  padding: 0 15px 15px 15px;
  -ms-overflow-x: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  overflow: hidden;
  min-height: 500px;
}

.content-wrapper {
  padding-bottom: 50px;
}

.content-header {
  font-family: kalpurush, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

.datatable-header {
  padding-left: 5px;
  font-family: kalpurush, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

.langEn {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.btn-idra {
  width: 125px;
}

.btn-idra-md {
  width: 100px;
}

.btn-idra-sm {
  width: 65px;
}

.btn-border-green {
  border-color: green;
}

.btn-border-blue {
  border-color: blue;
}

.bs-datepicker {
  left: 51px !important;
}

#password+.fa {
  cursor: pointer;
  pointer-events: all;
}

.changed-label {
  color: blue;
  padding-left: 10px;
  margin-bottom: 0px;
}

.content-header {
  /*border-bottom: 1px solid #d2d6de;*/
  padding: 10px 0px 15px 0 !important;
}

.breadcrumb {
  padding: 0px 0px !important;
  right: 2px !important;
}

.modal-content {
  top: 100px;
}

/*Custom CSS for ViewDetails*/
.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th {
  border: none;
}

.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th:hover {
  background-color: #fff;
}

.td-info {
  width: 40%;
  font-weight: 600;
  text-align: right;
}

.td-colon {
  width: 4%;
}

.editButton {
  cursor: pointer;
}

.mandatory-sign {
  color: red;
}

.red {
  color: red;
}

.green {
  color: green;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 1055;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #eee;
}

.ng-select.ng-select-single .ng-select-container {
  height: 34px !important;
}

.mat-dialog-title {
  padding: 15px 15px 0px 15px !important;
  margin-bottom: 10px !important;
}

.mat-dialog-container {
  padding: 0px !important;
  overflow: hidden !important;
}

.mat-dialog-content {
  margin: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #3c8dbc;
  color: #fff;
}

.mat-checkbox-inner-container {
  width: 14px !important;
  height: 14px !important;
  opacity: 0.8;
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  height: 20px;
  background-image: url(./assets/img/calendar-icon.svg) !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
  border-color: #fff;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 14px;
  left: 0;
  height: 100%;
  width: 100%;
}

.recordNotFoundText {
  text-align: center;
  font-size: 16px;
  font-style: oblique;
  padding-top: 10px;
}

.icon-right-space {
  padding-right: 5px;
}

body .ui-growl {
  position: fixed;
  top: 51px;
  right: 15px;
  width: 20em;
}

.ui-growl-image {
  position: absolute;
  display: inline-block;
  left: 0.5em;
  top: 0.65em;
  padding: 0;
  font-size: 1.7em;
}

body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-image {
  font-size: 1.871em;
}

/*Start Buttom Custom Disabled */
.btn-primary[disabled] {
  background: #e6e9ed;
  color: #aab2bd;
  border: 1px solid transparent;
}

.btn-primary[disabled]:hover {
  background: #e6e9ed;
  color: #aab2bd;
  border: 1px solid transparent;
}

.btn-danger[disabled] {
  background: #e6e9ed;
  color: #aab2bd;
  border: 1px solid transparent;
}

.btn-danger[disabled]:hover {
  background: #e6e9ed;
  color: #aab2bd;
  border: 1px solid transparent;
}

.btn-success[disabled] {
  background: #e6e9ed;
  color: #aab2bd;
  border: 1px solid transparent;
}

.btn-success[disabled]:hover {
  background: #e6e9ed !important;
  color: #aab2bd;
  border: 1px solid transparent;
}

.btn-info[disabled] {
  background: #e6e9ed;
  color: #aab2bd;
  border: 1px solid transparent;
}

.btn-info[disabled]:hover {
  background: #e6e9ed !important;
  color: #aab2bd;
  border: 1px solid transparent;
}

/*End Buttom Custom Disabled */

/* Success SVG Image */

svg {
  width: 50px;
  display: block;
  margin: 10px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

.p-message {
  text-align: center;
  margin: 20px 0 20px;
  font-size: 1.25em;
}

success {
  color: #73af55;
}

error {
  color: #d06079;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

/* End Success SVG Image */

/**Mobile number patteren  css**/
.error-message {
  color: red;
}

.success-message {
  color: green;
}

.isa_info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.isa_success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.isa_warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.isa_error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.isa_default {
  color: #333;
  background-color: #fff;
}

.mat-snack-bar-container {
  margin-right: 15px !important;
}

.mat-simple-snackbar {
  font-size: 15px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.mat-radio-button {
  font-size: 14px;
  font-family: kalpurush, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

.mat-checkbox {
  font-size: 14px;
  font-family: kalpurush, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

.custom-today-class {
  background-color: #e9edf0;
}

.wrap {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
}

#photo-id-front {
  margin: 5px 0;
  height: 500px;
}

#photo-id-front img {
  width: auto !important;
}


.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.view-table {
  width: 100%;
}

.view-table tr {
  border-bottom: 1px solid #e1e1e1;
}

.view-table .heading-row {
  background: #97a1a1;
  padding: 10px 30px;
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
}

.view-table .name {
  padding: 0 30px;
  width: 35%;
  background: #eeeeee;
  text-align: right;
  color: #464444;
  font-size: 13px;
  line-height: 35px;
  font-weight: 600;
}

.view-table .doc {
  padding: 0 15px;
  width: 100%;
  background: #eeeeee;
  text-align: left;
  color: #464444;
  font-size: 13px;
  line-height: 35px;
  font-weight: 600;
}

.view-table .value {
  padding: 0 30px;
  width: 65%;
  background: #ffffff;
  color: #222222;
  font-size: 14px;
  line-height: 22px;
}

.text-center {
  text-align: center;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0400ff !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #90baee !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #49c5b6;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 25px;
}

.switch.switch-medium {
  background-color: #fff !important;
  margin-bottom: 5px;
}

.switch.switch-medium.checked {
  background-color: rgb(196, 223, 230) !important;
}

.switch.switch-large {
  background-color: #fff !important;
  margin-bottom: 5px;
}

.switch.switch-large.checked {
  background-color: rgb(235, 220, 178) !important;
}

.switch-pane {
  font-weight: 500 !important;
}

button {
  outline: none;
  font-family: kalpurush !important;
}

.innerGridHeader {
  color: rgba(19, 126, 194, 0.87);
  font-weight: 600;
  font-size: 16px;
}

/* working code for print preview */

.printme {
  display: none;
}

@media print {
  .no-printme {
    display: none;
  }

  .printme {
    display: block;
  }

  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  @page {
    size: auto;
    margin-top: 0.5in;
    margin-bottom: 0.5in;
    margin-left: 0in;
    margin-right: 0in;
  }
}

/* end */

.img-pps {
  height: 180px;
  width: 180px;
  object-fit: contain;
}

.img-gen {
  height: 180px;
  width: 100%;
  object-fit: contain;
}

.modal {
  z-index: 1053 !important;
}

.modal-backdrop {
  z-index: 1052 !important;
}

.custom-today-class {
  background-color: #5bc0de;
  color: #fff;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-column-paymentStatus {
  text-align: center;
}

.mat-column-paymentDate {
  text-align: center;
}

.cdk-overlay-container {
  z-index: 1054 !important;
}

.has-error .form-control {
  border-width: 2px !important;
}

.has-error .ng-select-container {
  border-color: #dd4b39 !important;
  border-width: 2px !important;
}

.has-error .container-file-upload {
  border-color: #dd4b39 !important;
  border-width: 2px !important;
}

.has-error .applicant-man-file-heading {
  color: #dd4b39 !important;
}

.address-error .form-control {
  border-color: #dd4b39 !important;
  border-width: 2px !important;
}

.label-on-error .control-label {
  color: #dd4b39 !important;
}